import AWSLogo from './assets/aws-logo.png';
import ReactLogo from './assets/react-logo.png';
import DjangoLogo from './assets/django-logo.png';
import TerraformLogo from './assets/terraform-logo.png';
import ServerlessLogo from './assets/serverless-logo.png';
import OpenAPILogo from './assets/openapi-logo.png';
import JTPhoto from './assets/jt-photo.png';


const jlt = {
  name: 'Joshua Tucker',
  role: 'Senior Software Engineer',
  twitterUrl: 'https://twitter.com/tuckerwales',
  linkedinUrl: 'https://www.linkedin.com/in/tuckerwales/',
  githubUrl: 'https://github.com/tuckerwales'
}

export default function App() {
  return (
    <div className="bg-white">
      <div className="mx-auto py-4 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-8">
        <div className="space-y-12">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Hey, I'm Joshua Tucker!</h2>
            <p className="text-xl text-gray-500">
              I'm a full-stack senior software engineer currently living in Wales, United Kingdom.
            </p>
          </div>
          <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
            <div className="space-y-4">
              <div className="aspect-w-3 aspect-h-2">
                <img className="object-cover shadow-lg rounded-lg" src={JTPhoto} alt="Joshua Tucker" />
              </div>

              <div className="space-y-2">
                <div className="text-lg leading-6 font-medium space-y-1">
                  <h3>{jlt.name}</h3>
                  <p className="text-indigo-600">{jlt.role}</p>
                </div>
              </div>
            </div>
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-y-8 gap-x-6 lg:gap-x-8">
                <div className="col-span-1 flex justify-center p-8 bg-gray-50 rounded-lg">
                  <a href={jlt.twitterUrl} className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Twitter</span>
                    <svg className="w-12 h-12 filter grayscale" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </div>
                <div className="col-span-1 flex justify-center p-8 bg-gray-50 rounded-lg">
                  <a href={jlt.twitterUrl} className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">LinkedIn</span>
                    <svg className="w-12 h-12 filter grayscale" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
                <div className="col-span-1 flex justify-center p-8 bg-gray-50 rounded-lg">
                  <a href={jlt.githubUrl} className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">GitHub</span>
                    <svg className="w-12 h-12" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C13.71 14.53 16 11.53 16 8C16 3.58 12.42 0 8 0Z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </ul>
        </div>
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-12 lg:py-16">
            <p className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">
              Technologies that I work with
            </p>
            <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
              <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                <img
                  className="max-h-12"
                  src={AWSLogo}
                  alt="AWS"
                />
              </div>
              <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                <img className="max-h-12" src={ReactLogo} alt="React" />
              </div>
              <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                <img className="max-h-12 rounded-lg" src={DjangoLogo} alt="Django" />
              </div>
              <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                <img className="max-h-12" src={TerraformLogo} alt="Terraform" />
              </div>
              <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                <img
                  className="max-h-12"
                  src={ServerlessLogo}
                  alt="Serverless"
                />
              </div>
              <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                <img
                  className="max-h-12"
                  src={OpenAPILogo}
                  alt="OpenAPI"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
